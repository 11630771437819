import React, { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'

export interface ItemContentBlockProps {
  className?: string
  content: string | null | undefined
}

const ItemContentBlock: FC<ItemContentBlockProps> = ({ className = '', content = null }) => {
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!contentRef.current) {
      return
    }

    const removeListenerCallbacks: Array<() => void> = []
    const accListTitles = contentRef.current.getElementsByClassName('accordion-list-title')
    for (const accListTitle of accListTitles) {
      const plusCross = accListTitle.querySelector('[data-effect="plus-cross"]')
      const target = accListTitle.getAttribute('data-target')
      if (plusCross && target) {
        const targetContent = contentRef.current.querySelector(target)
        if (targetContent) {
          const listener = () => {
            targetContent.classList.toggle('in')
            plusCross.classList.toggle('open')
          }
          accListTitle.addEventListener('click', listener)
          removeListenerCallbacks.push(() => accListTitle.removeEventListener('click', listener))
        }
      }
    }
    return () => {
      removeListenerCallbacks.forEach((fn) => fn())
    }
  }, [])

  return content ? (
    <section className={className}>
      <div
        ref={contentRef}
        // TODO: don't use `dangerouslySetInnerHTML` if possible
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </section>
  ) : null
}

const StyledItemContentBlock = styled(ItemContentBlock)(({ theme }) => ({
  // These classes are being used to emulate the bootstrap
  // classes that are automatically being set via NetSuite
  '.accordion-list-item:first-child': {
    marginTop: '0',
    borderTop: '0',
  },
  '.accordion-list-item': {
    borderRight: '0',
    borderLeft: '0',
    marginTop: '-1px',
  },
  '.accordion-list-item:first-child .accordion-list-title': {
    paddingTop: '0',
  },
  '.accordion-list-title': {
    padding: '15px 0',
    cursor: 'pointer',
  },
  '.title-extra-small': {
    fontFamily: theme.fontFamilies.gotham,
    fontSize: '18px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '1.2',
  },
  '.plus-cross': {
    position: 'relative',
    width: '20px',
    height: '20px',
    margin: '0 10px 10px',
  },
  '.accordion-list-icon': {
    float: 'right',
  },
  '.open .line-1': {
    transform: 'rotate(-45deg) translate(0)',
  },
  '.line-1': {
    transform: 'rotate(90deg) translate(-0.5px)',
  },
  '.line': {
    height: '2px',
    background: '#051120',
    width: '20px',
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    right: '0',
    left: '0',
    transition: '.3s all',
  },
  '.open .line-2': {
    transform: 'rotate(45deg)',
  },
  '.collapse.in': {
    display: 'block',
    visibility: 'visible',
  },
  '.accordion-list-content': {
    padding: '0 0 15px',
  },
  '.collapse': {
    display: 'none',
    visibility: 'hidden',
  },
  '.copy-size-3': {
    fontFamily: theme.fontFamilies.gotham,
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: '21px',
  },
  a: {
    color: 'inherit',
  },
}))

export default StyledItemContentBlock
